import React from 'react';
import { IconButton, Modal } from '@material-ui/core';
import { withTrans } from '../../i18n/withTrans';
import PropTypes from 'prop-types';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 220,
  bgcolor: 'background.paper',
  boxShadow: 24,
  pt: 2,
  px: 2,
  pb: 3,
  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'column',
  margin: 'auto',
  padding: 30,
  outline: 0,
  outlineStyle: 'none'
};

const CalendarNoteModal = ({ t, state, dispatch }) => {
  const onClose = () => {
    dispatch({ showCalendarNote: false, activeStep: state.activeStep + 1 });
  };

  return (
    <Modal
      disableEscapeKeyDown
      onClose={() => onClose()}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
      open={state.showCalendarNote}
    >
      <div style={style}>
        <p>{t('reservationPage.calendar-note')}</p>
        <p style={{ fontWeight: 'italic' }}>
          {t('reservationPage.patrimonial-days-note')}
        </p>
        <div style={{ position: 'absolute', right: 10, bottom: 15 }}>
          <IconButton onClick={onClose}>Ok</IconButton>
        </div>
      </div>
    </Modal>
  );
};

export default withTrans(CalendarNoteModal);

CalendarNoteModal.propTypes = {
  t: PropTypes.func.isRequired,
  state: PropTypes.shape({
    showCalendarNote: PropTypes.bool.isRequired,
    activeStep: PropTypes.number.isRequired
  }).isRequired,
  dispatch: PropTypes.func.isRequired
};
