/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core';
import useAuthContext from '../../contexts/AuthContext';
import MomentUtils from '@date-io/moment';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import * as moment from 'moment';
import { withTrans } from '../../i18n/withTrans';
import { countryTranslations } from '../../locales/countryTranslations';

const VisitorAdultInfoForm = ({
  indexVisitor,
  reservationState,
  reservationDispatch,
  t,
  ...props
}) => {
  const newFormsList = [...reservationState.visitorsList];
  const { dispatchAPI } = useAuthContext();

  const getPays = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: `/gts/pays` });
      const fr = data.ListePays.Pays.find(
        (el) => el._attributes.Libelle === 'FRANCE'
      );
      const frIndex = data.ListePays.Pays.findIndex(
        (el) => el._attributes.Libelle === 'FRANCE'
      );
      data.ListePays.Pays.splice(frIndex, 1);

      const translateCountry = (countryName) => {
        const translations = countryTranslations[countryName];
        if (translations) {
          return translations[props.language] || countryName;
        }
        return countryName;
      };

      const list = [
        {
          ...fr,
          _attributes: {
            ...fr._attributes,
            Libelle: translateCountry(fr._attributes.Libelle)
          }
        },
        ...data.ListePays.Pays.map((country) => ({
          ...country,
          _attributes: {
            ...country._attributes,
            Libelle: translateCountry(country._attributes.Libelle)
          }
        }))
      ];
      reservationDispatch({ pays: list });
    } catch (e) {
      // eslint-disable-next-line no-console
      if (e.response) console.error(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getPays();
    })();
  }, [props.language]);

  const validationEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email.toLowerCase());
  };

  const validatePhone = (phone) => {
    let check = false;
    const phoneTest =
      /^(?:(?:\+|00)?([0-9]{0,3})|0)\s*[1-9](?:[\s.-]*\d{2}){4,5}$/;
    if (phone.match(phoneTest)) {
      check = true;
    }

    return check;
  };

  const handleInputChange = (name) => (event) => {
    let email_validation = '';
    const { value } = event ? event.target || { target: {} } : '';

    switch (name) {
      case 'email':
        newFormsList[indexVisitor].email = value;
        if (!validationEmail(value)) {
          reservationDispatch({
            errors_validation: [
              ...(reservationState.errors_validation || []),
              'email_format'
            ]
          });
        } else {
          reservationDispatch({
            errors_validation: reservationState.errors_validation.filter(
              (el) => el !== 'email_format'
            )
          });
        }
        break;
      case 'email_validation':
        {
          email_validation = value;
          newFormsList[indexVisitor].email_validation = value;
          if (newFormsList[indexVisitor].email !== value) {
            reservationDispatch({
              errors_validation: [
                ...(reservationState.errors_validation || []),
                'email_validation'
              ]
            });
          } else if (newFormsList[indexVisitor].email === email_validation) {
            reservationDispatch({
              errors_validation: (
                reservationState.errors_validation || []
              ).filter((el) => el !== 'email_validation')
            });
          }
        }
        break;
      case 'prenom':
        newFormsList[indexVisitor].prenom = value;
        break;
      case 'nom':
        newFormsList[indexVisitor].nom = value;
        break;
      case 'datenaissance':
        newFormsList[indexVisitor].datenaissance =
          moment(event).format('YYYYMMDD') || '';
        break;
      case 'postal_code':
        newFormsList[indexVisitor].codepostal = value;
        break;
      case 'tel':
        newFormsList[indexVisitor].telephone = value;
        if (!validatePhone(value)) {
          reservationDispatch({
            errors_validation: [
              ...(reservationState.errors_validation || []),
              'phone_format'
            ]
          });
        } else {
          reservationDispatch({
            errors_validation: (
              reservationState.errors_validation || []
            ).filter((el) => el !== 'phone_format')
          });
        }
        break;
      default:
        break;
    }
    reservationDispatch({
      visitorsList: newFormsList
    });
  };

  const addToNewsletter = (checked) => {
    newFormsList[indexVisitor].newsletter = checked;
  };

  useEffect(() => {
    if (indexVisitor === 0) {
      if (newFormsList[indexVisitor].prenom) {
        let newReceiverList = [
          {
            name: `${newFormsList[indexVisitor].prenom} ${newFormsList[indexVisitor].nom}`,
            email: newFormsList[indexVisitor].email
          }
        ];
        reservationDispatch({ receiverList: newReceiverList });
      }
    }
  }, [
    newFormsList[indexVisitor].nom,
    newFormsList[indexVisitor].prenom,
    newFormsList[indexVisitor].email
  ]);

  const handlePaysChange = (value) => {
    newFormsList[indexVisitor].pays = value;

    reservationDispatch({
      visitorsList: newFormsList
    });
  };

  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' }
      }}
      noValidate
      autoComplete="off"
      className="adult-form-wrapper"
    >
      <FormControl>
        <div
          style={{
            width: '85%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            minHeight: 210
          }}
        >
          <div className="visitor-form-column datepicker-wrapper">
            <TextField
              required
              error={
                reservationState.errors_validation?.includes(indexVisitor) &&
                !newFormsList[indexVisitor].prenom
              }
              label={t('visitors.form.first_name')}
              onChange={handleInputChange('prenom')}
              value={newFormsList[indexVisitor].prenom || ''}
            />
            <TextField
              required
              error={
                reservationState.errors_validation?.includes(indexVisitor) &&
                !newFormsList[indexVisitor].nom
              }
              label={t('visitors.form.last_name')}
              onChange={handleInputChange('nom')}
              value={newFormsList[indexVisitor].nom || ''}
            />
            <MuiPickersUtilsProvider
              utils={MomentUtils}
              locale={props.language === 'fr-FR' ? 'fr' : props.language}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end'
                }}
              >
                <span>{t('visitors.form.birth_date')}</span>
                <KeyboardDatePicker
                  placeholder={moment().format('DD/MM/YYYY')}
                  format="DD/MM/YYYY"
                  value={
                    moment(newFormsList[indexVisitor].datenaissance) ||
                    moment(new Date())
                  }
                  onChange={handleInputChange('datenaissance')}
                />
              </div>
            </MuiPickersUtilsProvider>
          </div>
          <div className="email-form-row">
            <TextField
              type="email"
              required
              helperText={
                (reservationState.errors_validation || []).includes(
                  'email_format'
                ) && t('errors.email_format')
              }
              error={
                (reservationState.errors_validation?.includes(indexVisitor) &&
                  !newFormsList[indexVisitor].email) ||
                reservationState.errors_validation?.includes('email_format')
              }
              label={t('visitors.form.email')}
              onChange={handleInputChange('email')}
              value={newFormsList[indexVisitor].email || ''}
              style={{ minWidth: 225 }}
            />
            <TextField
              required
              label={t('visitors.form.confirm_email')}
              onChange={handleInputChange('email_validation')}
              helperText={
                (reservationState.errors_validation || []).includes(
                  'email_validation'
                ) && t('errors.email_validation')
              }
              error={
                reservationState?.errors_validation?.includes(
                  'email_validation'
                ) ||
                reservationState.errors_validation?.includes('email_format') ||
                (reservationState.errors_validation?.includes(indexVisitor) &&
                  !newFormsList[indexVisitor].email_validation)
              }
              value={newFormsList[indexVisitor].email_validation || ''}
              style={{ minWidth: 225 }}
            />
            <div className="checkbox-wrapper">
              <Checkbox
                // checked={newFormsList[indexVisitor].newsletter}
                onClick={(event) => addToNewsletter(event.target.checked)}
              />
              <span>{t('visitors.form.newsletter')}</span>
            </div>
          </div>
          <div
            className="visitor-form-column"
            // style={{ minHeight: 125 }}
          >
            <div style={{ display: 'flex', alignItems: 'flex-end' }}>
              <FormControl>
                <InputLabel id="demo-simple-select-label">
                  {t('visitors.form.country')}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  style={{ minWidth: 225 }}
                  value={newFormsList[indexVisitor].pays || ''}
                  onChange={(event) => handlePaysChange(event.target.value)}
                >
                  {(reservationState.pays || []).map(
                    (city) =>
                      city && (
                        <MenuItem
                          key={city._attributes.Code}
                          value={city._attributes.Code}
                        >
                          {city._attributes.Libelle}
                        </MenuItem>
                      )
                  )}
                </Select>
              </FormControl>
              {/* <Autocomplete
                  id="country-select-demo"
                  sx={{ width: 300 }}
                  value={newFormsList[indexVisitor].pays || ''}
                  onChange={(event, newValue ) => {
                    handlePaysChange(newValue)
                  }}
                  inputValue={newFormsList[indexVisitor].pays || ''}
                  onInputChange={(event, newInputValue) => {
                    handlePaysChange(newValue)
                  }}
                  options={(reservationState.pays || [])}
                  autoHighlight
                  getOptionLabel={(option) => option._attributes.Libelle}
                  renderOption={(props, option) => {
                  //  console.log('props', props)
                    return (
                        <Box onClick={event => console.log('e',event)} component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                          {option._attributes.Libelle}
                        </Box>
                    )
                  }}
                  renderInput={(params) => {
                    return (
                        <TextField
                            {...params}
                            label={t('visitors.form.country')}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password', // disable autocomplete and autofill
                             // onChange: (event) =>  handlePaysChange(event.target.value)
                            }}
                        />
                    )
                  }}
              />*/}
            </div>
            <TextField
              label={t('visitors.form.postal_code')}
              onChange={handleInputChange('postal_code')}
              value={newFormsList[indexVisitor].codepostal || ''}
            />
            <TextField
              required
              label={t('visitors.form.phone')}
              onChange={handleInputChange('tel')}
              value={newFormsList[indexVisitor].telephone || ''}
              helperText={
                reservationState.errors_validation?.includes('phone_format') &&
                t('errors.phone_format')
              }
              error={
                reservationState.errors_validation?.includes('phone_format') ||
                (reservationState.errors_validation?.includes(indexVisitor) &&
                  !newFormsList[indexVisitor].telephone)
              }
              className="phone-textfield"
            />
          </div>
        </div>
      </FormControl>
    </Box>
  );
};

export default withTrans(VisitorAdultInfoForm);

VisitorAdultInfoForm.propTypes = {
  indexVisitor: PropTypes.number.isRequired,
  reservationState: PropTypes.shape({
    visitorsList: PropTypes.arrayOf(
      PropTypes.shape({
        prenom: PropTypes.string,
        nom: PropTypes.string,
        email: PropTypes.string,
        email_validation: PropTypes.string,
        datenaissance: PropTypes.string,
        codepostal: PropTypes.string,
        telephone: PropTypes.string,
        pays: PropTypes.string,
        newsletter: PropTypes.bool
      })
    ),
    errors_validation: PropTypes.arrayOf(PropTypes.string),
    pays: PropTypes.arrayOf(
      PropTypes.shape({
        _attributes: PropTypes.shape({
          Code: PropTypes.string.isRequired,
          Libelle: PropTypes.string.isRequired
        }).isRequired
      })
    )
  }).isRequired,
  reservationDispatch: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  props: PropTypes.shape({
    language: PropTypes.string
  }).isRequired
};
