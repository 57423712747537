/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import {
  Card,
  IconButton,
  List,
  ListItem,
  CircularProgress
} from '@material-ui/core';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import { extendMoment } from 'moment-range';
import useAuthContext from '../../contexts/AuthContext';
import { withTrans } from '../../i18n/withTrans';
import { useHandleResize } from '../../utils';
import SelectMonth from '../SelectMonth';
import PopUpErrors from '../ReservationPage/PopUpErrors';
import { DownArrowIcon } from '../../assets/customIcons';
import scrollTo from 'gatsby-plugin-smoothscroll';

function reducer(state, action) {
  return { ...state, ...action };
}

const isBrowser = typeof window !== 'undefined';

const Calendar = ({ reservationState, reservationDispatch, ...props }) => {
  const moment = extendMoment(Moment);
  const { dispatchAPI } = useAuthContext();
  const nodeEnv = process.env.NODE_ENV;

  let full_price = '0';
  let reduced_price = '0';
  let free_price = '0';
  let language = '0';
  let visitType = '0';
  let selectedMonth = '';

  if (isBrowser) {
    full_price = localStorage.getItem('full_price');
    reduced_price = localStorage.getItem('reduced_price');
    free_price = localStorage.getItem('free_price');
    language = localStorage.getItem('language');
    visitType = localStorage.getItem('visitType');
    selectedMonth = JSON.parse(localStorage.getItem('selectedMonth'));
  }

  const totalVisitor =
    JSON.parse(full_price) + JSON.parse(reduced_price) + JSON.parse(free_price);
  const initialState = {
    currentYear: moment(new Date()),
    month: selectedMonth
      ? moment(selectedMonth)
      : moment().month(moment().month()),
    calendar_loader: false
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  const [offset, setOffset] = React.useState(0);

  const getManifestations = async (start, end) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/gts?date_debut=${moment(start).format(
          'YYYYMMDD'
        )}&date_fin=${moment(end).format('YYYYMMDD')}&language=${language}`
      });
      reservationDispatch({ manifestations: data });
    } catch (e) {
      if (e.response) console.error(e.response.status);
    }
  };

  const getJauges = async (date) => {
    try {
      dispatch({ calendar_loader: true, disable_button: true });
      const list = [];
      const { data } = await dispatchAPI('GET', {
        url: `/gts/jauge-manif?date_debut=${moment(date).format(
          'YYYYMMDD'
        )}&date_fin=${moment(date).format('YYYYMMDD')}&language=${language}`
      });
      reservationDispatch({
        manifestations_with_jauge: [
          ...reservationState.manifestations_with_jauge,
          ...data
        ]
      });
      data.forEach((manif) => {
        const filtersSplit = manif.theme.split('');
        if (visitType === '17') {
          if (
            `${filtersSplit[0]}${filtersSplit[1]}` === '17' &&
            filtersSplit[2] === 'H' &&
            filtersSplit[3] === language &&
            manif.jauge >= totalVisitor &&
            moment(`${manif.date}T${manif.heure}`).diff(moment(), 'hours') >= 2
          ) {
            list.push(manif);
          }
        } else if (
          (`${filtersSplit[0]}${filtersSplit[1]}` === visitType ||
            `${filtersSplit[0]}${filtersSplit[1]}` === 'PE') &&
          filtersSplit[2] === 'H' &&
          filtersSplit[3] === language &&
          JSON.parse(manif.jauge) >= totalVisitor &&
          moment(`${manif.date} ${manif.heure}`).diff(moment(), 'hours') >= 2
        ) {
          list.push(manif);
        }
      });
      dispatch({ calendar_loader: false, disable_button: false });
      return list;
    } catch (e) {
      if (e.response) console.error(e.response.status);
    }
  };

  /* useEffect(() => {
        if (state.range && state.range.length) {
            (async  () => {
                await getJauges();
            })();
        }
    }, [getJauges])*/

  useEffect(() => {
    const list = [];
    (reservationState.manifestations_with_jauge || []).forEach((manif) => {
      const filtersSplit = manif.theme.split('');
      if (visitType === '17') {
        if (
          `${filtersSplit[1]}${filtersSplit[2]}` === '17' &&
          filtersSplit[3] === 'I' &&
          filtersSplit[4] === language &&
          JSON.parse(manif.jauge) >= totalVisitor
        ) {
          list.push(manif);
        }
      } else if (
        (`${filtersSplit[1]}${filtersSplit[2]}` === visitType ||
          `${filtersSplit[1]}${filtersSplit[2]}` === 'PE') &&
        filtersSplit[3] === 'I' &&
        filtersSplit[4] === language &&
        JSON.parse(manif.jauge) >= totalVisitor
      ) {
        list.push(manif);
      }
    });

    reservationDispatch({
      manifestations_available:
        nodeEnv === 'development'
          ? list
          : list.filter(
              (el) =>
                moment(el.date).isSame(moment(new Date()).format('YYYYMMDD')) ||
                moment(el.date).isAfter(moment(new Date()))
            )
    });
    /* setTimeout(() => {
            dispatch({ calendar_loader: false, disable_button: false});
        }, 3000);*/
  }, [
    reservationState.manifestations_with_jauge,
    language,
    visitType,
    totalVisitor
  ]);

  /*    useEffect(() => {
        const list = [...reservationState.manifestations_with_jauge];
        (reservationState.manifestations || []).forEach(manif => {
            (async () => {
                const jauge = await getJauges(manif.date, manif.code);
                list.push({...manif,  jauge: jauge.jaugeDispo })
                reservationDispatch({
                    manifestations_with_jauge: list
                })
            })();
        })

    }, [reservationState.manifestations]);*/

  useEffect(() => {
    const firstDayOfMonth = moment(state.month).startOf('month');
    setOffset((firstDayOfMonth.day() + 6) % 7);
    const lastDayOfMonth = moment(state.month).endOf('month');
    const range = moment().range(firstDayOfMonth, lastDayOfMonth);

    const datesList = Array.from(range.by('days', { step: 1 }));
    dispatch({
      dates: datesList,
      range: [
        state.month.isSame(moment(), 'month') ? moment() : firstDayOfMonth,
        lastDayOfMonth
      ],
      lastDayOfMonth: lastDayOfMonth
    });
    getManifestations(firstDayOfMonth, lastDayOfMonth);
  }, [state.month, moment, state.bool]);

  /*    useEffect(() => {
        reservationDispatch({manifestations_with_jauge: [
                {
                    code: '254152',
                    site: 'OPERA',
                    salle: '801',
                    theme: 'OIMIF',
                    date: '20211007',
                    heure: '1445',
                    jauge: 3
                },
                {
                    code: '254152',
                    site: 'OPERA',
                    salle: '801',
                    theme: 'OIMIF',
                    date: '20211007',
                    heure: '1545',
                    jauge: 3
                },
                {
                    code: '254152',
                    site: 'OPERA',
                    salle: '801',
                    theme: 'O17IF',
                    date: '20211009',
                    heure: '1445',
                    jauge: 3
                },
                {
                    code: '452632',
                    site: 'OPERA',
                    salle: '801',
                    theme: 'O17IF',
                    date: '20211009',
                    heure: '1545',
                    jauge: 45
                },
            ]});
    }, [reservationDispatch]);*/

  const nextMonth = () => {
    if (state.month.format('MM') === '12') {
      dispatch({ currentYear: moment(state.currentYear).add(1, 'y') });
    }
    dispatch({ month: moment(state.month).add(1, 'M') });
  };

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const previousMonth = async () => {
    if (state.month.format('MM') === '01') {
      dispatch({ currentYear: moment(state.currentYear).subtract(1, 'y') });
    }
    dispatch({ month: moment(state.month).subtract(1, 'M') });
    await sleep(2000);
  };

  const clickScroll = () => {
    scrollTo('#calendar_scroll', 'start');
  };

  const onSelectDate = async (date) => {
    if (
      (reservationState.selectedDates || [])
        .map((el) => el.date)
        .includes(date.format('YYYYMMDD'))
    ) {
      reservationDispatch({
        ticketsList: [],
        selectedDates: reservationState.selectedDates.filter(
          (el) => el.date !== moment(date).format('YYYYMMDD')
        ),
        manifestations_with_jauge:
          reservationState.manifestations_with_jauge.filter(
            (el) => el.date !== moment(date).format('YYYYMMDD')
          )
      });
    } else {
      const list = await getJauges(date);
      if (!list.length) {
        reservationDispatch({ openModal: true });
      } else {
        reservationDispatch({
          selectedDates: [
            // ...(reservationState.selectedDates || []),
            ...list
          ]
        });
        clickScroll();
      }
    }
  };

  const handleClose = () => {
    reservationDispatch({ openModal: false });
  };

  const { width } = useHandleResize();

  return (
    <Card className="calendar-wrapper">
      {/* <InformNotAvailabilitiesModal open={reservationState.openModal} setOpen={reservationDispatch} />*/}
      <PopUpErrors
        open={reservationState.openModal}
        handleClose={handleClose}
        text="availabilities"
      />
      <h1>
        <IconButton
          style={{
            opacity:
              state.disable_button ||
              state.month.isSame(moment().month(), 'month')
                ? 0.4
                : 1,
            cursor:
              state.disable_button ||
              state.month.isSame(moment().month(), 'month')
                ? 'not-allowed'
                : 'pointer'
          }}
          disabled={
            state.disable_button ||
            state.month.isSame(moment().month(), 'month')
          }
          onClick={() => previousMonth()}
        >
          <AiOutlineLeft />
        </IconButton>
        {state.month.locale(props.language).format('MMMM').toLowerCase()}{' '}
        {state.month.format('YYYY')}
        <IconButton
          style={{
            opacity: state.disable_button ? 0.4 : 1,
            cursor: state.disable_button ? 'not-allowed' : 'pointer'
          }}
          disabled={state.disable_button}
          onClick={() => nextMonth()}
        >
          <AiOutlineRight />
        </IconButton>
        <SelectMonth calendarState={state} calendarDispatch={dispatch} />
        {width < 1700 && (reservationState.selectedDates || []).length !== 0 && (
          <div
            style={{
              position: 'absolute',
              right: -10,
              top: width < 370 ? 30 : -5,
              color: 'white'
            }}
            className="landing-readmore-link"
          >
            {/* <a href="#calendar_scroll">
                            <DownArrowIcon />
                            </a>*/}
            {/* <a href={`${location.pathname}#calendar_scroll`}><DownArrowIcon /></a>*/}
            {/* <AnchorLink stripHash to="/ma-visite#calendar_scroll" title="Our team">
                            <DownArrowIcon />
                            </AnchorLink>*/}
            <IconButton onClick={() => clickScroll()}>
              <DownArrowIcon />
            </IconButton>
          </div>
        )}
      </h1>
      <div>
        <List className="calendar-list">
          {offset > 0
            ? [...Array(offset).keys()].map((el, index) => (
                <ListItem key={index} onClick={() => {}}></ListItem>
              ))
            : null}
          {(state.dates || []).map((date) => (
            <ListItem key={date} onClick={() => onSelectDate(date)}>
              <Card>
                <button
                  className={
                    new Date(moment(date).add(1, 'days')).getTime() <
                      new Date().getTime() ||
                    reservationState.manifestations?.filter((mani) => {
                      const filtersSplit = mani.theme.split('');
                      return (
                        (`${filtersSplit[0]}${filtersSplit[1]}` === visitType ||
                          `${filtersSplit[0]}${filtersSplit[1]}` === 'PE') &&
                        filtersSplit[2] === 'H' &&
                        filtersSplit[3] === language &&
                        JSON.parse(mani.jauge) >= totalVisitor &&
                        moment(`${mani.date} ${mani.heure}`).diff(
                          moment(),
                          'hours'
                        ) >= 2 &&
                        moment(mani.date).format('YYYYMMDD') ===
                          moment(date).format('YYYYMMDD')
                      );
                    })?.length === 0
                      ? 'disabled calendar-btn'
                      : (reservationState.selectedDates || [])
                          .map((el) => el.date)
                          .includes(date.format('YYYYMMDD'))
                      ? 'selected calendar-btn'
                      : 'calendar-btn'
                  }
                >
                  <div>
                    <span>{date.format('DD')}</span>
                    <span>
                      {date.locale(props.language).format('dddd').toLowerCase()}
                    </span>
                  </div>
                </button>
              </Card>
            </ListItem>
          ))}
        </List>
      </div>

      {state.calendar_loader && (
        <div
          style={{
            position: 'absolute',
            zIndex: 10,
            top: '45vh',
            left: '30vw'
          }}
        >
          <CircularProgress color="black" thickness={1} size={150} />
        </div>
      )}
    </Card>
  );
};

export default withTrans(Calendar);

Calendar.propTypes = {
  reservationState: PropTypes.shape({
    manifestations_with_jauge: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string,
        site: PropTypes.string,
        salle: PropTypes.string,
        theme: PropTypes.string,
        date: PropTypes.string,
        heure: PropTypes.string,
        jauge: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      })
    ),
    manifestations_available: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string,
        site: PropTypes.string,
        salle: PropTypes.string,
        theme: PropTypes.string,
        date: PropTypes.string,
        heure: PropTypes.string,
        jauge: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      })
    ),
    selectedDates: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string,
        code: PropTypes.string
      })
    ),
    manifestations: PropTypes.arrayOf(
      PropTypes.shape({
        theme: PropTypes.string,
        jauge: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      })
    ),
    openModal: PropTypes.bool
  }).isRequired,
  reservationDispatch: PropTypes.func.isRequired,
  props: PropTypes.shape({
    language: PropTypes.string
  }).isRequired
};
